@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  body {
    @apply bg-dark_back
  }

  .toast_head {
    @apply fixed right-0;
  }

  .toast_body {
    @apply relative m-4 p-4 rounded-md bg-dark_surf border border-slate-800 border-l-8 border-l-blue-500 text-white;
  }

  .toast_green {
    @apply border-l-8 border-l-green-500;
  }

  .toast_red {
    @apply border-l-8 border-l-red-900;
  }

  article {
    @apply flex flex-col rounded-md bg-dark_surf shadow-2xl m-4 px-6 py-4;
  }

  form {
    @apply flex flex-col rounded-md bg-dark_surf m-4 py-4;
  }

  summary {
    @apply flex justify-between cursor-pointer border-b pt-4 pb-2 mb-2 text-slate-300 border-slate-800 hover:text-opacity-80;
  }

  .details_body {
    @apply flex flex-col p-2 mb-4 border rounded-lg border-slate-800;
  }

  .summary_block li {
    @apply text-lg;
  }

  input {
    @apply bg-dark_back border-gray-800 border p-4 mb-4 rounded-md;
  }

  select {
    @apply w-40 bg-dark_back p-4 mb-4 rounded-md;
  }

  label {
    @apply mb-2;
  }

  /* BUTTONS */

  .btn {
    @apply bg-blue-700 hover:bg-blue-900 text-center align-middle p-4 rounded-md outline-none;
  }

  .btn_pill {
    @apply border-2 text-cyan-300 border-cyan-800 bg-cyan-950 hover:bg-cyan-900 text-center align-middle px-3 py-2 rounded-3xl outline-none self-baseline;
  }

  .btn_small {
    @apply w-14 mx-0;
  }

  .btn_red {
    @apply bg-red-900 hover:bg-red-950;
  }

  h1 {
    @apply text-xl sm:text-3xl font-bold;
  }

  h2 {
    @apply mb-4 text-2xl font-bold;
  }

  h3 {
    @apply mb-4 text-xl font-bold;
  }

  h4 {
    @apply mb-4 text-lg font-bold;
  }
}

details>summary {
  list-style-type: none;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open]>summary .summary_icon {

  animation: rotation 0.3s forwards;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
}

.toast_body {
  animation: slideIn 0.5s;
}

.slide_out {
  animation: slideOut forwards 0.5s;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(150%);
  }
}